<template>
  <div class="bg">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script lang="ts" setup>

useHead(computed(() => ({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Mijn KHN` : 'Mijn KHN';
  },
  charset: 'utf-8',
  link: [
    {
      rel: 'stylesheet',
      href: 'https://use.typekit.net/bzr3gsi.css',
    },
  ],
})));
</script>

const fileRemove = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"/></svg>';

/**
 * Object with default FormKit icons
 * @return {{select?: string, search?: string, checkboxDecorator?: string, radioDecorator?: string}}
 */

export const formKitIcons = {
  fileRemove,
};


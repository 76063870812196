import {provideApolloClient} from '@vue/apollo-composable';
import type {ErrorResponse} from '@apollo/client/link/error';

export default defineNuxtPlugin((nuxtApp) => {
  const isClient = process.client;

  // Provide global Apollo client for use in composable
  provideApolloClient(useApollo().clients.default);

  nuxtApp.hook('apollo:error', (error: ErrorResponse) => {
    console.error('Graphql Error:', error);
    if (isClient) {
      nuxtApp.payload.state.graphqlErrors = error.graphQLErrors;
    } else if (nuxtApp.ssrContext) {
      nuxtApp.ssrContext.payload.state.graphqlErrors = error.graphQLErrors;
    }
  });
});

<template>
  <client-only>
    <VDatePicker
      v-model="date"
      mode="date"
      v-bind="pickerAttributes"
      :attributes="attrs"
      locale="nl"
      :popover-align="'center'"
      @update:model-value="doInput"
    >
      <template #default="{inputValue, togglePopover}">
        <div
          class="relative cursor-pointer"
          @click="!isDisabled ? togglePopover({placement: 'bottom'}) : null"
        >
          <input
            type="text"
            class="focus:border-text-highlight w-full rounded border-grey-200 p-3 px-2.5 pr-10 text-black placeholder-black-500 outline-none transition hover:border-primary-200 focus:ring-0 formkit-disabled:bg-black-300 formkit-disabled:text-black-400"
            :value="formatInputValue(inputValue)"
            :placeholder="pickerAttributes.placeholder"
            readonly
          >
          <UiIcon
            class="absolute right-4 top-1/2 -translate-y-1/2 text-primary-200"
            name="calendar"
            size="medium"
          />
        </div>
      </template>
      <template #footer>
        <div class="w-full px-4 pb-3">
          <button
            type="button"
            class="btn btn-primary w-full py-2"
            @click="resetValue"
          >
            Reset
          </button>
        </div>
      </template>
    </VDatePicker>
  </client-only>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';
import type {DatePickerDate} from '@angelblanco/v-calendar/src/use/datePicker';
import {useDateHelper} from 'website/utils/date';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const {format, formatRange} = useDateHelper();

const attrs = ref([
  {
    key: 'today',
    highlight: {
      class: 'today',
    },
  },
]);

const pickerAttributes = computed(() => Object.assign(props.context.attrs, {id: null, class: null}));
const isDisabled = computed(() => props.context.disabled || 'disabled' in props.context.attrs);
const isRangeInput = computed(() => 'is-range' in props.context.attrs);

const date = computed({
  get: () => props.context._value,
  set: () => ({}),
});

function doInput(event: DatePickerDate) {
  let inputValue = event;
  if (event) {
    inputValue = isRangeInput.value ? formatRange(event.start, event.end) : format(event, null, 'yyyy-LL-dd');
  }

  props.context.node.input(inputValue);
  props.context.handlers.blur();
}

function resetValue() {
  props.context.node.input(null);
  props.context.handlers.blur();
}

function formatInputValue(value: DatePickerDate) {
  if (isRangeInput.value) {
    if (!value.start) {
      return '';
    }

    return `${value.start} - ${value.end}`;
  }

  return value;
}
</script>

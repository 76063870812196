import revive_payload_client_lAoMaG8W6Y from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BsEYtze53D from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_H4Ft2xfZIm from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_nmue5Th7sZ from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.9.0_lightning_4p3xo6ngje3twp342oxk2li5ii/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_S2Heni2VX7 from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_CCtmheFQAJ from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zQ1o4ffNku from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cPG5hXnmW0 from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/.nuxt/components.plugin.mjs";
import prefetch_client_6R3HGE6oba from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HWdcBDiV8g from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.26.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_d4gMb26Uuj from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EUU9tQlPNI from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/.nuxt/formkitPlugin.mjs";
import plugin_q9UgSrnveb from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.2_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_qperiwavgtrmmpcphhjewsudv4/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_6OJ3DpQCyb from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_@ope_y362j6ypwl3fjdyjouji4xeeqi/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/.nuxt/floating-vue.mjs";
import plugin_tV1l6FR942 from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.14.159_@parcel+watcher@2.5.0_@types+no_jgbviwqrj7rmqbewyyax3pbifi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import errors_h1Wsffhcxj from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.14.159_@parcel+wa_weyegvxxzx36465bigoza3yroa/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_jMd0xFYUTQ from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.14.159_@parcel+wa_weyegvxxzx36465bigoza3yroa/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_xUMRLwFYyD from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_xlmxxvfauweh57nwqxwbbgytle/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_ktYIv27oho from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.1_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_xlmxxvfauweh57nwqxwbbgytle/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_ttA8Ql9zEo from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__ywebhpuskkcfwh4l465bdi4rhy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_wlRde3u6Qp from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__ywebhpuskkcfwh4l465bdi4rhy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import api_GFfDXud5Cr from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/plugins/api.ts";
import apollo_1ILFkzpYSr from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/plugins/apollo.ts";
import v_calendar_dxaVzst4iS from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/plugins/v-calendar.ts";
import vue3_toastify_OGYNDsiW9E from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_lAoMaG8W6Y,
  unhead_BsEYtze53D,
  router_H4Ft2xfZIm,
  _0_siteConfig_nmue5Th7sZ,
  payload_client_S2Heni2VX7,
  navigation_repaint_client_CCtmheFQAJ,
  check_outdated_build_client_zQ1o4ffNku,
  chunk_reload_client_cPG5hXnmW0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6R3HGE6oba,
  plugin_HWdcBDiV8g,
  switch_locale_path_ssr_d4gMb26Uuj,
  i18n_EUU9tQlPNI,
  formkitPlugin_pZqjah0RUG,
  plugin_q9UgSrnveb,
  plugin_6OJ3DpQCyb,
  floating_vue_EIcJ7xiw0h,
  plugin_tV1l6FR942,
  errors_h1Wsffhcxj,
  i18n_jMd0xFYUTQ,
  apollo_xUMRLwFYyD,
  sofie_required_version_ktYIv27oho,
  apollo_ttA8Ql9zEo,
  auth_wlRde3u6Qp,
  api_GFfDXud5Cr,
  apollo_1ILFkzpYSr,
  v_calendar_dxaVzst4iS,
  vue3_toastify_OGYNDsiW9E
]
<template>
  <div class="bg-primary-50">
    <NuxtLayout :name="layout">
      <div
        class="mx-auto flex h-full flex-col items-center justify-center"
        :class="[isPageNotFound ? 'w-full' : 'w-1/3']"
      >
        <template v-if="isPageNotFound">
          <img
            src="@/assets/images/404.svg"
            alt="404"
            class="pb-7"
          >
          <p class="max-w-xl pb-10 text-center">
            Oeps, we kunnen de pagina die je zoekt helaas niet vinden. Probeer terug te gaan naar de vorige pagina of
            klik op de knop om terug te gaan naar je dashboard. Onze excuses voor het ongemak.
          </p>
          <NuxtLink
            class="btn btn-primary"
            :to="{name: 'dashboard'}"
          >
            Naar dashboard
            <IconsHome class="mb-1" />
          </NuxtLink>
        </template>
        <div
          v-else-if="isMaintenanceMode"
          class="max-w-2/3 flex flex-col items-center text-center"
        >
          <img
            src="@/assets/images/logo.png"
            alt="KHN logo"
            class="w-32 pb-7"
          >
          <h1 class="mb-3 text-4xl">
            Under construction
          </h1>
          <span class="text-center">
            Vanwege een omvangrijke update is deze website helaas niet beschikbaar. Volgende week zijn onze webshop en de portal weer online. Onze excuses voor het ongemak en bedankt voor je geduld.
          </span>
        </div>
        <template v-else>
          <img
            src="@/assets/images/oeps.svg"
            alt="404"
            class="pb-7"
          >
          <p class="max-w-xl pb-10 text-center">
            <template v-if="isUnauthorized">
              Je hebt geen toegang tot deze pagina. Probeer terug te gaan naar de vorige pagina of
              klik op de knop om terug te gaan naar je dashboard. Onze excuses voor het ongemak.
            </template>
            <template v-else>
              Er is iets fout gegaan. Probeer terug te gaan naar de vorige pagina of
              klik op de knop om terug te gaan naar je dashboard. Onze excuses voor het ongemak.
            </template>
          </p>
          <NuxtLink
            class="btn btn-primary"
            :to="{name: 'dashboard'}"
          >
            Naar dashboard
            <IconsHome class="mb-1" />
          </NuxtLink>
        </template>
      </div>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import type {NuxtError} from '#app';

const props = defineProps<{
  error: NuxtError,
}>();

useHead({
  title: props.error.statusMessage,
});

const isPageNotFound = computed(() => props.error.statusCode === 404);
const isMaintenanceMode = computed(() => props.error.statusCode === 503);
const isUnauthorized = computed(() => props.error.message === 'Forbidden' || props.error.statusCode === 401);

const layout = isMaintenanceMode.value ? 'error' : 'default';
</script>

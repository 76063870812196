export default defineNuxtPlugin(() => {
  const {data} = useAuth();
  const {public: publicRuntimeConfig} = useRuntimeConfig();

  const $api = $fetch.create({
    baseURL: `${publicRuntimeConfig.apiUrl}/api`,
    onRequest({options}) {
      if (data.value?.accessToken) {
        options.headers = options.headers || {};
        options.headers.authorization = `Bearer ${data.value.accessToken}`;
      }
    },
  });

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api: $api,
    },
  };
});

import {DateTime, Settings} from 'luxon';
import type {DateInput} from '~/types';

export const useDateHelper = () => {
  Settings.defaultLocale = 'nl';

  const parseDate = (date: DateInput): DateTime => {
    if (DateTime.isDateTime(date)) {
      return date;
    } else if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return DateTime.fromFormat(date, 'yyyy-LL-dd');
    } else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
      return DateTime.fromFormat(date, 'yyyy-LL-dd HH:mm:ss');
    } else {
      return DateTime.fromISO(date);
    }
  };

  const today = (): string => {
    const now = new Date();
    return `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
  };

  const format = (date?: DateInput, defaultValue: string | null = null, format: string = 'dd-LL-yyyy'): string | null => {
    if (!date) {
      return defaultValue;
    }

    const parsedDate = parseDate(date);

    return parsedDate.toFormat(format);
  };

  const formatLong = (date?: DateInput, defaultValue: string | null = null): string | null => {
    if (!date) {
      return defaultValue;
    }
    const parsedDate = parseDate(date);

    return parsedDate.toFormat('dd LLL yyyy');
  };

  const formatReadable = (date: string|null) => {
    if (!date) {
      return '-';
    }

    const hasTime = date.includes(':');
    const fromFormat = `yyyy-LL-dd${hasTime ? ' HH:mm:ss' : ''}`;
    const toFormat = `d MMMM yyyy${hasTime ? ' \'om\' HH:mm' : ''}`;

    return DateTime
      .fromFormat(date, fromFormat)
      .setLocale('nl')
      .toFormat(toFormat);
  };

  const formatTime = (date?: DateInput, defaultValue: string | null = null): string | null => {
    if (!date) {
      return defaultValue;
    }
    const parsedDate = parseDate(date);

    return parsedDate.toFormat('HH:mm');
  };

  const formatRange = (start: DateInput, end: DateInput): unknown => {
    const parsedStart = parseDate(start);
    const parsedEnd = parseDate(end);

    return {
      start: parsedStart.toFormat('yyyy-LL-dd'),
      end: parsedEnd.toFormat('yyyy-LL-dd'),
    };
  };

  return {
    format,
    formatLong,
    formatReadable,
    formatTime,
    formatRange,
    today,
  };
};

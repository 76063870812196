import { default as _91_46_46_46slug_93d7exfpYzH6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/[...slug].vue?macro=true";
import { default as adressenkORhS1jPWyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexprTznm7Bo6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkersYZoazpFVIeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexaBN3iw0yRTMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as index8AonE4HLfhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexFtmjLx6RR0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93fMZPBYrRKLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexkm8kvE51T6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/facturen/index.vue?macro=true";
import { default as indexqiwLXUvKF7Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/index.vue?macro=true";
import { default as indexNsrd7xXFKKMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassornAQk4U6NtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexN7qltfhMPXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexXRY1HhMA0lMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenZB2nHAt0BRMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountKtA5oHBZBXMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account.vue?macro=true";
import { default as loginAgfcrJYQRuMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/login.vue?macro=true";
import { default as _91token_93CjqgegabOUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93LwytQiFhAMMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexgY7QKaJWSaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_932z9hWO1kDxMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademiewfL6gzutGFMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentenoDuOCrBGonMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexceI9DSJASlMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersTR5zDjtwITMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders.vue?macro=true";
import { default as dashboard1CIpuFl0kvMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/dashboard.vue?macro=true";
import { default as evenementenS59h3VOajdMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/evenementen.vue?macro=true";
import { default as indexbz9p6TWfKWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/index.vue?macro=true";
import { default as kennisbankRXWfVjWcPHMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/kennisbank.vue?macro=true";
import { default as _91id_933BuCBKaUjmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/klanten/[id].vue?macro=true";
import { default as indexeGAi6jR0pcMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/klanten/index.vue?macro=true";
import { default as afnemenqkyOpFfu8qMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexd1e8X426vnMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexGkg3q9ZJzUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelenBHMka7mbr4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen.vue?macro=true";
import { default as indexf4L5By0P7bMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenf5tGKzNHToMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizeJFikO8YOXvMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/oauth/authorize.vue?macro=true";
import { default as stylesZwN5D4caMJMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/styles.vue?macro=true";
import { default as aangemeldfBNouLPCEDMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue?macro=true";
import { default as aanmeldenOK2IFO7VuLMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue?macro=true";
import { default as indexJSxvyE6UuFMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/[slug]/index.vue?macro=true";
import { default as indext71WDdDLwjMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/index.vue?macro=true";
import { default as trainingenbavyY4mjagMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen.vue?macro=true";
import { default as _91id_93V8F4GJdkkaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexag7UXBg2DbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93dYk0Y3cGd9Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexq2eMqTE9efMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/[category]/index.vue?macro=true";
import { default as cartgzdkwQu9ayMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/cart.vue?macro=true";
import { default as checkoutDm8fMCWLQhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/checkout.vue?macro=true";
import { default as confirmationvSQlFBDs4rMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/confirmation.vue?macro=true";
import { default as indexmVw2RsFCCKMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/index.vue?macro=true";
import { default as webshopKowt6IO6TzMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop.vue?macro=true";
import { default as zoeken55NZE3WZ92Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/zoeken.vue?macro=true";
import { default as indexgyUr7bIfdFMeta } from "~/pages/trainingen/[slug]/index.vue?macro=true";
import { default as component_45stubU6oTO9ybHnMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubU6oTO9ybHn } from "/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_lightningc_jr7wpywqet2gby5zuir4nonaxm/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93d7exfpYzH6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/[...slug].vue")
  },
  {
    name: accountKtA5oHBZBXMeta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account.vue"),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    meta: adressenkORhS1jPWyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue")
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    meta: indexprTznm7Bo6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue")
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    meta: medewerkersYZoazpFVIeMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue")
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    meta: indexaBN3iw0yRTMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/bedrijfsgegevens/index.vue")
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/communicatievoorkeuren/index.vue")
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    meta: indexFtmjLx6RR0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/evenementen/index.vue")
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    meta: _91factuur_93fMZPBYrRKLMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/facturen/[factuur].vue")
  },
  {
    name: "account-facturen",
    path: "facturen",
    meta: indexkm8kvE51T6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/facturen/index.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexqiwLXUvKF7Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/index.vue")
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    meta: indexNsrd7xXFKKMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/ledenvoordelen/index.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    meta: incassornAQk4U6NtMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    meta: indexN7qltfhMPXMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue")
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    meta: indexXRY1HhMA0lMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/lidmaatschap/index.vue")
  },
  {
    name: "account-nieuwsbriefvoorkeuren",
    path: "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenZB2nHAt0BRMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/account/nieuwsbriefvoorkeuren.vue")
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginAgfcrJYQRuMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/login.vue")
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93CjqgegabOUMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93LwytQiFhAMMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: indexgY7QKaJWSaMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue")
  },
  {
    name: bestuurdersTR5zDjtwITMeta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders.vue"),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_932z9hWO1kDxMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/[slug].vue")
  },
  {
    name: "bestuurders-bestuurdersacademie",
    path: "bestuurdersacademie",
    meta: bestuurdersacademiewfL6gzutGFMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/bestuurdersacademie.vue")
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenoDuOCrBGonMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/dossiers-documenten.vue")
  },
  {
    name: "bestuurders",
    path: "",
    meta: indexceI9DSJASlMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/bestuurders/index.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/dashboard.vue")
  },
  {
    name: "evenementen",
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/evenementen.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexbz9p6TWfKWMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/index.vue")
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/kennisbank.vue")
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_933BuCBKaUjmMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/klanten/[id].vue")
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: indexeGAi6jR0pcMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/klanten/index.vue")
  },
  {
    name: ledenvoordelenBHMka7mbr4Meta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen.vue"),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemenqkyOpFfu8qMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue")
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: indexd1e8X426vnMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen/[slug]/index.vue")
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexGkg3q9ZJzUMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/ledenvoordelen/index.vue")
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: indexf4L5By0P7bMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/lid-worden/index.vue")
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenf5tGKzNHToMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/nieuwsbrief/uitschrijven.vue")
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorizeJFikO8YOXvMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/oauth/authorize.vue")
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/styles.vue")
  },
  {
    name: trainingenbavyY4mjagMeta?.name,
    path: "/trainingen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen.vue"),
    children: [
  {
    name: "trainingen-slug-uuid-aangemeld",
    path: ":slug()/:uuid()/aangemeld",
    meta: aangemeldfBNouLPCEDMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue")
  },
  {
    name: "trainingen-slug-uuid-aanmelden",
    path: ":slug()/:uuid()/aanmelden",
    meta: aanmeldenOK2IFO7VuLMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue")
  },
  {
    name: "trainingen-slug",
    path: ":slug()",
    meta: indexJSxvyE6UuFMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/[slug]/index.vue")
  },
  {
    name: "trainingen",
    path: "",
    meta: indext71WDdDLwjMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/trainingen/index.vue")
  }
]
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93V8F4GJdkkaMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/verkoopkansen/[id].vue")
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: indexag7UXBg2DbMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/verkoopkansen/index.vue")
  },
  {
    name: webshopKowt6IO6TzMeta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop.vue"),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93dYk0Y3cGd9Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/[category]/[product].vue")
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexq2eMqTE9efMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/[category]/index.vue")
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cartgzdkwQu9ayMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/cart.vue")
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkoutDm8fMCWLQhMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/checkout.vue")
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmationvSQlFBDs4rMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/confirmation.vue")
  },
  {
    name: "webshop",
    path: "",
    meta: indexmVw2RsFCCKMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/webshop/index.vue")
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/78/nuxt/website/pages/zoeken.vue")
  },
  {
    name: "bestuurders-trainingen-slug",
    path: "/bestuurders/trainingen/:slug()",
    meta: indexgyUr7bIfdFMeta || {},
    component: () => import("~/pages/trainingen/[slug]/index.vue")
  },
  {
    name: component_45stubU6oTO9ybHnMeta?.name,
    path: "/evenementen/:pathMatch(.*)",
    component: component_45stubU6oTO9ybHn
  }
]
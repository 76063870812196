import {Roles} from '~/types';

export default defineNuxtRouteMiddleware((to) => {
  const user = useSofieUserWhenAuthenticated();

  // TODO: remove after update of nuxt/auth and replace with guest middleware
  if (user.value && to.name === 'index') {
    return navigateTo('/dashboard');
  }

  if (user.value && to.name !== 'lid-worden' && user.value?.role_id === Roles.Lid && !user.value?.has_active_membership) {
    return navigateTo('/lid-worden');
  }
});
